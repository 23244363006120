<template>
  <i-container
    v-if="response"
    class="
      _margin-top-xs-0
      _margin-top-sm-0
      _margin-top-md-6
      _margin-top-lg-6
      _margin-top-xl-6
      _margin-bottom-6
    "
  >
    <i-row>
      <i-column>
        <i-container>
          <prismic-rich-text
            :field="response.data.artist_statement"
            class="bock--small"
          />
          <prismic-image
            :field="response.data.image.scaled"
            class="
              image
              -responsive
              _width-50
              _padding-right-1 _padding-bottom-1
            "
          />
          <prismic-rich-text :field="response.data.bio" class="bock--small" />
          <prismic-rich-text :field="response.data.cv" class="bock--small" />
        </i-container>
      </i-column>
    </i-row>
    <!-- <pre v-if="response" v-html="JSON.stringify(response, null, 2)"></pre> -->
  </i-container>
</template>

<script>
export default {
  name: "About",
  data() {
    return {
      response: null,
    };
  },
  methods: {
    async getContent() {
      const response = await this.$prismic.client.getSingle("about");
      this.response = response;
    },
  },
  created() {
    this.getContent();
  },
};
</script>

<style lang="scss">
.bock--about {
  width: 50%;
}
</style>
